.App {
  text-align: center;
}

.m10 {
  margin: 10px;
}

.m20 {
  margin: 20px;
}

.m50 {
  margin: 50px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.center {
  display: flex;
  justify-content: center;
}

.hoverBox:hover {
  font-size: 25px;
}

/* INSIGHT */
.insight-default .MuiBox-root {
  text-align: center;
}

.insight-results {
  display: flex;
  justify-content: center;
}

/* QUOTE */
.quote {
  display: flex;
  justify-content: center;
  font-size: 0.9rem;
}

@media only screen and (max-width: 1400px) {
  .quote {
    font-size: 0.7rem;
  }
}

@media only screen and (max-width: 768px) {
  .quote {
    font-size: 0.9rem;
  }
}

.quote .MuiGrid-container {
  justify-content: center;
}

.quote .MuiGrid-container {
  padding: 0;
}

.quote .quote-values {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: space-between;
}

.quote .quote-values-title {
  min-width: 1px;
  justify-content: flex-start;
}

.quote .quote-values-value {
  min-width: 1px;
  justify-content: flex-end;
}

.quote .css-887cpn-MuiPaper-root {
  box-shadow: none;
}

/* Loading Spinner */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-container {
  margin-top: 5em;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

/* Login */
.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
